import { render, staticRenderFns } from "./register.vue?vue&type=template&id=b43a939c&"
import script from "./register.vue?vue&type=script&lang=js&"
export * from "./register.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RegisterPageDesktopLogo: require('/codebuild/output/src3783517001/src/hogc-one-frontend/components/RegisterPage/RegisterPageDesktopLogo.vue').default,RegisterPageDesktopEllipse: require('/codebuild/output/src3783517001/src/hogc-one-frontend/components/RegisterPage/RegisterPageDesktopEllipse.vue').default,RegisterPageMobileLogo: require('/codebuild/output/src3783517001/src/hogc-one-frontend/components/RegisterPage/RegisterPageMobileLogo.vue').default,RegisterHeader: require('/codebuild/output/src3783517001/src/hogc-one-frontend/components/RegisterPage/RegisterHeader.vue').default,RegisterPageMobileEllipse: require('/codebuild/output/src3783517001/src/hogc-one-frontend/components/RegisterPage/RegisterPageMobileEllipse.vue').default})
