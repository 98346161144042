/* eslint-disable space-before-function-paren */
const BOARD_THEME_COLOUR_MAP = {
  SPL: { 2023: '#FD5A1E', 2024: '#39D2A5' },
  SPG: { 2023: '#0357D4', 2024: '#ECE2D2' },
  SPH: { 2023: '#0A9860', 2024: '#795134' },
  SPC: { 2023: '#FEC72C', 2024: '#FEC72C' },
  SPLyn: { 2024: '#75474A', 2025: '#75474A' },
}

const BOARD_THEME_TEXT_COLOUR_MAP = {
  SPL: { 2023: '#FFFFFF', 2024: '#0B0C0F' },
  SPG: { 2023: '#FFFFFF', 2024: '#0B0C0F' },
  SPH: { 2023: '#FFFFFF', 2024: '#F8F9F9' },
  SPC: { 2023: '#1D428A', 2024: '#1D428A' },
  SPLyn: { 2024: '#E8DBD5', 2025: '#E8DBD5' },
}

const BOARD_THEME_BACKGROUND_MAP = {
  SPC: { nav: 'SPC-navbar', sidebar: 'SPC-sidebar' },
}

const BOARD_SELECT_NAME_MAP = {
  SPH: { emoji: '🛩', name: 'SPH', det: 'sph-page' },
  SPG: { emoji: '🍜', name: 'SPG', det: 'pg-page' },
  SPL: { emoji: '⚾', name: 'SPL', det: 'spl-page' },
  SPC: { emoji: '🏀', name: 'SPC', det: 'pc-page' },
  SPLyn: { emoji: '🎤', name: 'SPLYN', det: 'pl-page' },
}

export const state = () => ({
  isLoading: true,
  formHeader: null,
  submitButton: null,
  currPost: null,
  isBoardToastOpen: false,
  boardToastMessage: '',
  filePreview: '',
  isCurrBoardAdmin: false,
  boards: [],
  showSideBar: false,
  showOptionsModal: false,
  showFilterModal: false,
  currBoard: {},
  readFilterValue: 'all',
  myZoneFilterValue: 'all',
  tags: [],
  zoneFilters: [],
  tagFilters: [],
  showDeletePage: false,
  isYearSelected: false,
})

export const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setFormHeader(state, formHeader) {
    state.formHeader = formHeader
  },
  setSubmitButton(state, submitButton) {
    state.submitButton = submitButton
  },
  setCurrPost(state, currPost) {
    state.currPost = currPost
  },
  setIsBoardToastOpen(state, isBoardToastOpen) {
    state.isBoardToastOpen = isBoardToastOpen
  },
  setBoardToastMessage(state, message) {
    state.boardToastMessage = message
  },
  setFilePreview(state, filePreview) {
    state.filePreview = filePreview
  },
  setIsCurrBoardAdmin(state, isCurrBoardAdmin) {
    state.isCurrBoardAdmin = isCurrBoardAdmin
  },
  setBoards(state, boards) {
    boards.forEach((board) => {
      board.selectName = BOARD_SELECT_NAME_MAP[board.name]
      board.backgroundTheme = BOARD_THEME_BACKGROUND_MAP[board.name]
      board.year = board.yearList[board.yearList.length - 1]
      board.themeColour = BOARD_THEME_COLOUR_MAP[board.name][board.year]
      board.themeTextColour = BOARD_THEME_TEXT_COLOUR_MAP[board.name][board.year]
    })
    state.boards = boards
  },
  setShowSideBar(state, showSideBar) {
    state.showSideBar = showSideBar
  },
  setShowOptionsModal(state, showOptionsModal) {
    state.showOptionsModal = showOptionsModal
  },
  setShowFilterModal(state, showFilterModal) {
    state.showFilterModal = showFilterModal
  },
  setCurrBoard(state, currBoard) {
    state.currBoard = currBoard
  },
  setReadFilterValue(state, readFilterValue) {
    state.readFilterValue = readFilterValue
  },
  setMyZoneFilterValue(state, myZoneFilterValue) {
    state.myZoneFilterValue = myZoneFilterValue
  },
  setZoneFilters(state, zoneFilters) {
    state.zoneFilters = zoneFilters
  },
  setTags(state, tags) {
    state.tags = tags
  },
  setTagFilters(state, tagFilters) {
    state.tagFilters = tagFilters
  },
  setDeletePage(state, showDeletePage) {
    state.showDeletePage = showDeletePage
  },
  setIsYearSelected(state, isYearSelected) {
    state.isYearSelected = isYearSelected
  },
  setCurrBoardYear(state, year) {
    state.currBoard.year = year
    // Update theme colour and text colur based on design
    state.currBoard.themeColour = BOARD_THEME_COLOUR_MAP[state.currBoard.name][year]
    state.currBoard.themeTextColour = BOARD_THEME_TEXT_COLOUR_MAP[state.currBoard.name][year]
  },
}

export const actions = {
  updateFormHeader({ commit }, formHeader) {
    commit('setFormHeader', formHeader)
  },
  updateSubmitButton({ commit }, submitButton) {
    commit('setSubmitButton', submitButton)
  },
  updateCurrPost({ commit }, currPost) {
    commit('setCurrPost', currPost)
  },
  updateIsBoardToastOpen({ commit }, isBoardToastOpen) {
    commit('setIsBoardToastOpen', isBoardToastOpen)
    if (isBoardToastOpen) {
      setTimeout(() => {
        commit('setIsBoardToastOpen', false)
      }, 3000)
    }
  },
  updateBoardToastMessage({ commit }, message) {
    commit('setBoardToastMessage', message)
  },
  updateFilePreview({ commit }, filePreview) {
    commit('setFilePreview', filePreview)
  },
  async fetchIsCurrBoardAdmin({ commit }, boardId) {
    const { data } = await this.$axios.get(`/board/${boardId}/is-admin`)
    commit('setIsCurrBoardAdmin', data.isBoardAdmin)
    commit('setIsLoading', false)
  },
  async fetchBoards({ commit }, queryBoardId, cookieBoard = null) {
    commit('setIsLoading', true)
    const { data } = await this.$axios.get('/board')
    commit('setBoards', data)
    // fetchBoards prioritizes query in URL, then cookie, then active board for first board render
    if (queryBoardId) {
      const board = data.find((board) => parseInt(board.id) === parseInt(queryBoardId))
      const yearList = board?.yearList || [] // Safely access yearList
      const lastestYear = yearList[yearList.length - 1]
      commit('setCurrBoard', board)
      commit('setCurrBoardYear', lastestYear)
      commit('setIsYearSelected', true)
    } else if (cookieBoard) {
      const board = data.find((board) => parseInt(board.id) === parseInt(cookieBoard.id))
      commit('setCurrBoard', board)
      commit('setCurrBoardYear', cookieBoard.year)
      commit('setIsYearSelected', cookieBoard.isYearSelected)
    } else {
      const board = data.find((board) => board.isActive)
      const yearList = board?.yearList || [] // Safely access yearList
      const lastestYear = yearList[yearList.length - 1]
      commit('setCurrBoard', board)
      commit('setCurrBoardYear', lastestYear)
      commit('setIsYearSelected', true)
    }
  },
  async fetchTags({ commit, state }) {
    if (!state.isCurrBoardAdmin) return
    const boardId = state.currBoard?.id
    if (!boardId) return
    const tags = await this.$axios.$get(`/board/${boardId}/posts/all-tags`)
    commit('setTags', tags)
  },
  updateShowSideBar({ commit }, showSideBar) {
    commit('setShowSideBar', showSideBar)
  },
  updateShowOptionsModal({ commit }, showOptionsModal) {
    commit('setShowOptionsModal', showOptionsModal)
  },
  updateShowFilterModal({ commit }, showFilterModal) {
    commit('setShowFilterModal', showFilterModal)
  },
  updateCurrBoard({ commit }, currBoard) {
    commit('setCurrBoard', currBoard)
  },
  updateReadFilterValue({ commit }, readFilterValue) {
    commit('setReadFilterValue', readFilterValue)
  },
  updateMyZoneFilterValue({ commit }, myZoneFilterValue) {
    commit('setMyZoneFilterValue', myZoneFilterValue)
  },
  updateZoneFilterValue({ commit }, zoneFilters) {
    commit('setZoneFilters', zoneFilters)
  },
  updateTagFilterValue({ commit }, tagFilters) {
    commit('setTagFilters', tagFilters)
  },
  updateDeletePage({ commit }, showDeletePage) {
    commit('setDeletePage', showDeletePage)
  },
  updateCurrBoardYear({ commit }, year) {
    commit('setCurrBoardYear', year)
  },
  updateIsYearSelected({ commit }, isYearSelected) {
    commit('setIsYearSelected', isYearSelected)
  },
  resetFilters ({ commit }) {
    commit('setMyZoneFilterValue', 'all')
    commit('setReadFilterValue', 'all')
    commit('setZoneFilters', [])
    commit('setTagFilters', [])
  },
}
