import { render, staticRenderFns } from "./birthday-board.vue?vue&type=template&id=509db1ba&scoped=true&"
import script from "./birthday-board.vue?vue&type=script&lang=js&"
export * from "./birthday-board.vue?vue&type=script&lang=js&"
import style0 from "./birthday-board.vue?vue&type=style&index=0&id=509db1ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509db1ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BoardHeader: require('/codebuild/output/src3783517001/src/hogc-one-frontend/components/Board/Main/BoardHeader.vue').default})
